@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

body {
   font-family: 'Roboto', sans-serif !important;
}

.footer-main {
  .w-80 {
  }
}

nav {

   a {
      color: #000 !important;
      font-weight: bold !important;
       border: 3px solid transparent;

      &:hover {
         background: inherit !important;
         color: #3b82f6 !important;
         border-bottom: 3px solid  #3b82f6;
      }
   }
}

.login-btn {
   color: #fff !important;
}



@media only screen and (max-width: 767px) {
    .footer-main {
       flex-direction: column;
    }

    .footer-image {
      img {
         max-width: 100%;
         height: auto;
      }
    }

    .w-dpds {
       width: 20rem !important;
    }

    .login-box {
      .login-con {
         width: 100% !important;
      }
    }

    .login-btn {
       display: none;
    }
}

@media (max-width: 767px) {
  .navicon {
    width: 1.125em;
    height: .125em;
  }

  .navicon::before,
  .navicon::after {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    transition: all .2s ease-out;
    content: '';
    background: #3D4852;
  }

  .navicon::before {
    top: 5px;
  }

  .navicon::after {
    top: -5px;
  }

  .menu-btn:not(:checked) ~ .menu {
    display: none;
  }

  .menu-btn:checked ~ .menu {
    display: block;
  }

  .menu-btn:checked ~ .menu-icon .navicon {
    background: transparent;
  }

  .menu-btn:checked ~ .menu-icon .navicon::before {
    transform: rotate(-45deg);
  }

  .menu-btn:checked ~ .menu-icon .navicon::after {
    transform: rotate(45deg);
  }

  .menu-btn:checked ~ .menu-icon .navicon::before,
  .menu-btn:checked ~ .menu-icon .navicon::after {
    top: 0;
  }


 .slider-main {
    flex-direction: column-reverse;
   .col {
       width: 100% !important;
   }
 }

  .slider-container, .slider-images {
      height: 300px !important;
  }

  .slider-container {
      margin-bottom: 70px;
  }

  .header-border {
     display: block !important;

     button {
        margin-top:40px;
     }
  }

  .featured-article {
      flex-direction: column;
  }

  .contact_map {
    flex-direction: column;
    .col {
        width: 100% !important;
        margin-bottom: 20px;
    }

    .relative {
       div:nth-child(1) {
          position: inherit !important;
       }
    }
  }

  .chart-col {
    .col {
       width: 100% !important;
    }
  }

}



ul.social-media-list {
    list-style: none;
		margin: 0;
		padding: 0;
    display: flex;

		li {
			vertical-align:  middle;
      margin: 7px;
			a {
				display: block;
				font-size: 1.4em;
				margin: 0 5px;
				text-decoration: none;

        img {
           height: 20px;
           width: 20px;
        }

      }
  }
}


.drop-menu {
    display: none;
    position: absolute;
    background-color: #fff;
    min-width: 150px;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
    padding: 10px;
    margin-top: 12px;
}

.drop-menu-item {
    width: 100%;
    padding: 5px;
}

.drop-menu-item:hover {
    background-color: #eee;
}

.drop-menu-item a {
    color: #555;
}

.menu-item:hover .drop-menu {
    display: block;
}


.bannger-page {
   background-repeat: no-repeat;
   background-image: url('../../../shared/images/Assets_DepEd-DPDS/Banner 1.png');
   padding-top: 50px;
   padding-bottom: 50px;
}


.slider-main {
    display: flex;
   .col {
      width: 50%;
   }
}

.flickity-page-dots .dot {
   background: #fff;
   height: 15px;
   width: 15px;
}

.flickity-page-dots .dot.is-selected {
    width: 40px;
    border-radius: 10px;
}

.slider-images {

  width: 100%;
  height: 500px;
  position: relative;

  &:after {
     content: '';
     position: absolute;
     top: 0;
     z-index: 1;
     background: #00000094;
     width: 100%;
     height: 100%;
  }

}

.slider-content {
   padding: 20px;
   color: #fff !important;
   display: flex;
   justify-content: space-between;
   position: absolute;
   width: 100%;
   bottom: 0;
   z-index: 2;

   h5 {
      font-weight: bold;
      color: #fff !important;
      font-size: 18px;
   }

   .text {
      max-width: 300px;
   }

   button {
      align-self: center;
   }

}

.slider-container {
   width: 500px;
}

.flickity-page-dots {
    bottom: -40px;
    text-align: right;
}

.bannger-page {
    color: #fff;
   .description {
      display: flex;
      flex-direction: column;
      justify-content: center;

      h3 {
         font-weight: bold;
         color: #fff;
         font-size: 2rem;
      }
      p {
         max-width: 500px;
         margin-top: 10px;
      }

      span {
         font-weight: bold;
         margin-top: 80px;
         font-size: 17px;
      }
   }
}


.featured-card {
   padding: 15px;
}

.partners-footer {
   list-style-type: none;
   color:#fff;

   .box {
      width: 40px;
      height: 40px;
      background: red;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      font-size: 19px;
      margin-right: 10px;
   }

   li {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
   }
}


.nav-footer {
   li {
     margin-bottom: 15px;
   }
}

.seal-ph {
   max-width: 100%;
   height: auto;
   margin: 0 auto;
   display: block;
   width: 150px;
   margin-top: 20px;
}

.deped-dpds {
   display: flex;

   img {
      width: 80px;
      max-width: 100%;
      height:auto;
      margin: 5px;
   }

}

.footer hr {
   width: 41%;
   background: #7a7a7ac7;
    height: 3px;
}

.w-dpds {
  width: 30rem;
}

#map {
  height: 400px;
  width: 100%;
}


.contact-details {
   width: 300px;
   height: 210px;
   position: absolute;
   top: 0;
   z-index: 1;
   right: 0;
   background: #0056ae;
}


.chart_main {
   display: flex;

   .col {
      width: 50%;
   }

}

.chart-card {
   max-width: 100%;
   height: 350px;
}


.ct-container {
   max-width: 1200px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.chart-col {
    flex-wrap: wrap;
   .col {
      width: 50%;
      padding: 10px;
      margin-bottom: 30px;
   }
}


.faq-list {
   .ant-collapse-item {
      padding: 10px;
      border-bottom: 0;
      background: #fff !important;
      font-weight: bold;
      font-size: 18px;


      p.desc {
         margin-left: 30px;
      }
   }
}
