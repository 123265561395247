@import url(~antd/dist/antd.css);

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --red-10: #d94647;
  --red-20: #cd1f30;
  --red-30: #d63d4b;
  --red-40: #ff4758;
  --blue-10: #0056AE;
  --blue-20: #00539a;
  --black-10 : #222222;
  --gray-10: #E9E9E9;
  --gray-20: #7F7F7F;
  --gray-30: #e3e3e3;
  --gray-40: #4e4e4e;
  --gray-50: #b9b9b9;
  --gray-60: #b1b1b1;
  --gray-70: #707070;
  --gray-80: #f0f0f1;
  --green-10: #42ba96;
  --gray-90: #f8f8f8;
  --black-10: #101010;
  font-family: "Nunito Sans", sans-serif;
}

body {
  font-family: "Nunito Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Utilities  */
/* Text Colors */
.text-red-10 {
  color: var(--red-10);
}
.text-black-10 {
  color: var(--black-10);
}
.text-yellow-10 {
  color: var(--yellow-10);
}
.text-purple-10 {
  color: var(--purple-10);
}
.text-brown-10 {
  color: var(--brown-10);
}
.text-green-10 {
  color: var(--green-10);
}
.text-red-20 {
  color: var(--red-20);
}
.text-red-30 {
  color: var(--red-30);
}
.text-red-40 {
  color: var(--red-40);
}
.text-blue-10 {
  color: var(--blue-10);
}
.text-blue-20 {
  color: var(--blue-20);
}
.text-gray-10 {
  color: var(--gray-10);
}
.text-gray-20 {
  color: var(--gray-20);
}
.text-gray-30 {
  color: var(--gray-30);
}
.text-gray-90 {
  color: var(--gray-90);
}
.text-gray-40 {
  color: var(--gray-40);
}
.text-gray-50 {
  color: var(--gray-50);
}
.text-gray-60 {
  color: var(--gray-60);
}
.text-black-10 {
  color: var(--black-10);
}

/* Background Colors */
.bg-yellow-10 {
  background: var(--yellow-10);
}
.bg-green-10 {
  background: var(--green-10);
}
.bg-black-10 {
  background: var(--black-10);
}
.bg-brown-10 {
  background: var(--brown-10);
}
.bg-purple-10 {
  background: var(--purple-10);
}
.bg-green-40 {
  background: var(--green-40);
}
.bg-gray-80 {
  background: var(--gray-80);
}
.bg-red-10 {
  background: var(--red-10);
}
.bg-red-20 {
  background: var(--red-20);
}
.bg-blue-10 {
  background: var(--blue-10);
}
.bg-blue-20 {
  background: var(--blue-20);
}
.bg-black-10 {
  background: var(--black-10);
}
.bg-gray-10 {
  background: var(--gray-80);
}

.bg-gray-90 {
  background: var(--gray-90);
}

/* Borders */
.border-h-gray {
  border-top: 1px solid #707070;
}
.border-blue-10 {
  border-color: var(--blue-10);
}
.border-gray {
  border-color: #c5c5c5;
}
.border-gray-90 {
  border-color: var(--gray-90);
}
.border-gray-20 {
  border-color: var(--gray-70);
}
.border-gray-30 {
  border-color: var(--gray-80);
}

.opac-bg {
  background-color: rgba(0, 0, 0, 0.3);
}

.card-rounded {
  border-radius: 20px;
}
.rounded-top {
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

.text-control-1 {
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-control-2 {
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-control-3 {
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hasErrorField {
  border-color: #e42f30 !important;
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  box-shadow: none !important;
}

.site-collapse-custom-collapse > .site-collapse-custom-panel > .ant-collapse-header  {
  padding: 0 !important;
}

.site-collapse-custom-collapse > .site-collapse-custom-panel {
  border: none !important;
}

/* ::-webkit-scrollbar {
  width: 0;
} */

.ant-btn{
  font-size: .8rem;
  font-weight: bold;
  color: black;
  /* border: none; */
}

.ant-btn:hover{
  background: #E9E9E9;
  color: white;
  border-radius: 2rem;
}

.ant-btn:focus{
  background: #E9E9E9;
  color: black;
}

.announcement-card {
  height: 31.5rem;
  width: 35rem;
}

.subannouncement-card {
  height: 15rem;
  width: 35rem;
}

.main-banner{
  background: rgb(0, 56, 168);
  height: 15rem;
}

.map{
  height: 35rem;
  width: 62rem;
}

.list-region {
  width: 28rem;
  height: 30rem;
}


/* ::-webkit-scrollbar {
  display: none;
} */

.horizontal-chart {
  width: 48.5rem;
  height: 56.5rem;
}

.vertical-chart {
  width: 48.5rem;
  height: 25rem;
}

.show-header {
  display: flex;
  justify-content: center;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  background: #FFF;
  align-items: center;
  transition: 0.5s ease-in;
  z-index: 50%;
}

.hide-header {
  display: flex;
  position: absolute;
  width: 100%;
  justify-content: center;
  background: #FFF;
  align-items: center;
  transition: 1s ease-out;
  height: 0;
  overflow: hidden;
}

.header-brand-container {
  display: flex;
  align-items: center;
}

.header-brand-container:hover {
  border-bottom-color: transparent;
}

.header-brand-container img {
  width: 65px;
  max-width: 100%;
  height: auto;
  margin: 5px;
}

.deped-dpds img {
  align-self: center;
}

.announcement-container {
  display: flex;
  justify-content: center;
  max-width: 100%;
}

.fb-post {
  display: flex;
  justify-content: center;
  margin-left: 18px;
}

.banner-btn {
  height: 40px;

}

.collapse-arrow{
  font-size: 1.5rem !important;
}

.contact-details {
  background-color: rgb(0, 56, 168) !important;
}

button {
  background-color: rgb(0, 56, 168) !important;
  color: white !important
}
